import { axios } from '@/utils/request'

const api = {
    pages: 'charging/merchant/pages', // 查询充电桩
    addMerchant: 'charging/merchant/addMerchant', //新增
    upMerchant: 'charging/merchant/upMerchant', // 编辑
    delMerchant: 'charging/merchant/delMerchant', //删除
    initialized: 'charging/merchant/initialized', // 初始化密码
}
export default api

export const merchantPagesApi = (params) => {
    return axios({
        url: api.pages,
        method: 'post',
        params
    })
}

export const addMerchantApi = (data) => {
    return axios({
        url: api.addMerchant,
        method: 'post',
        data
    })
}

export const upMerchantApi = (data) => {
    return axios({
        url: api.upMerchant,
        method: 'post',
        data
    })
}

export const delMerchantApi = (params) => {
    return axios({
        url: api.delMerchant,
        method: 'post',
        params
    })
}

export const initializedApi = (params) => {
    return axios({
        url: api.initialized,
        method: 'post',
        params
    })
}

<!-- 商户管理 -->
<template>
  <div class="warp">
    <el-card class="warp-content">
      <!--      <el-descriptions-->
      <!--        class="margin-top"-->
      <!--        title="商户基本信息"-->
      <!--        :column="3"-->
      <!--        size="large"-->
      <!--        border-->
      <!--      >-->
      <!--        <el-descriptions-item>-->
      <!--          <template #label>-->
      <!--            <div class="cell-item">-->
      <!--              商户名称-->
      <!--            </div>-->
      <!--          </template>-->
      <!--          kooriookami-->
      <!--        </el-descriptions-item>-->
      <!--        <el-descriptions-item>-->
      <!--          <template #label>-->
      <!--            <div class="cell-item">-->
      <!--              商户号-->
      <!--            </div>-->
      <!--          </template>-->
      <!--          18100000000-->
      <!--        </el-descriptions-item>-->
      <!--        <el-descriptions-item>-->
      <!--          <template #label>-->
      <!--            <div class="cell-item">-->
      <!--             联系人-->
      <!--            </div>-->
      <!--          </template>-->
      <!--          Suzhou-->
      <!--        </el-descriptions-item>-->
      <!--        <el-descriptions-item>-->
      <!--          <template #label>-->
      <!--            <div class="cell-item">-->
      <!--             联系电话-->
      <!--            </div>-->
      <!--          </template>-->
      <!--          18100000000-->
      <!--        </el-descriptions-item>-->
      <!--        <el-descriptions-item>-->
      <!--          <template #label>-->
      <!--            <div class="cell-item">-->
      <!--             地址-->
      <!--            </div>-->
      <!--          </template>-->
      <!--          No.1188, Wuzhong Avenue, Wuzhong District, Suzhou, Jiangsu Province-->
      <!--        </el-descriptions-item>-->
      <!--        <el-descriptions-item>-->
      <!--          <template #label>-->
      <!--            <div class="cell-item">-->
      <!--              所属用户-->
      <!--            </div>-->
      <!--          </template>-->
      <!--          kooriookami-->
      <!--        </el-descriptions-item>-->
      <!--        <el-descriptions-item>-->
      <!--          <template #label>-->
      <!--            <div class="cell-item">-->
      <!--              所属平台商-->
      <!--            </div>-->
      <!--          </template>-->
      <!--          <el-tag size="small">School</el-tag>-->
      <!--        </el-descriptions-item>-->
      <!--      </el-descriptions>-->
      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <el-input
              clearable
              placeholder="请输入登录账号"
              class="search-input"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="accountInput"
            ></el-input>
            <el-input
              clearable
              placeholder="请输入手机号"
              class="search-input"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="phoneInput"
            ></el-input>
            <el-select
              clearable
              class="search-input"
              placeholder="请选择角色"
              size="small"
              @change="searchInput"
              v-model="roleInput"
            >
              <el-option
                v-for="item in roleOption"
                :key="item.roleId"
                :label="item.roleName"
                :value="item.roleId"
                >{{ item.roleName }}</el-option
              >
            </el-select>
            <el-button type="primary" size="small" @click="searchInput"
              >搜索</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="addClick"
              v-if="userLevel === 0"
              >添加代理商</el-button
            >
          </el-col>
        </el-row>
      </div>
      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="630px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
          <!--          <el-table-column type="selection" align="center" width="80" />-->
          <el-table-column
            v-for="(item, index) in tableColumnData"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
          >
            <template #default="{ row }" v-if="item.prop === 'isDel'">
              <el-tag type="success" size="small" v-if="row.isDel == 0">{{
                statusSize(row)
              }}</el-tag>
              <el-tag type="danger" size="small" v-else>{{
                statusSize(row)
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="230"
            v-if="userLevel == 0"
          >
            <template #default="{ row }">
              <el-button
                :disabled="row.parentId == 0"
                type="text"
                @click="editClick(row)"
                >编辑</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-popconfirm
                v-if="row.isDel == 0"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="confirmDelete(row)"
                :title="'确认是否停用' + '“' + row.merchantName + '”' + '？'"
              >
                <template #reference>
                  <el-button type="text" :disabled="row.parentId == 0"
                    >停用</el-button
                  >
                </template>
              </el-popconfirm>
              <el-popconfirm
                v-else
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="confirmDelete(row)"
                :title="'确认是否启用' + '“' + row.merchantName + '”' + '？'"
              >
                <template #reference>
                  <el-button type="text" :disabled="row.parentId == 0"
                    >启用</el-button
                  >
                </template>
              </el-popconfirm>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="initPasswordClick(row)"
                >初始化密码</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
          :total="total"
          @pageChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
    </el-card>
    <!-- 新建设备 -->
    <el-dialog
      v-model="visible"
      width="40%"
      :title="titleName"
      :close-on-click-modal="false"
      @closed="resetForm"
    >
      <el-form
        label-width="95px"
        :inline="true"
        :model="formData"
        :rules="addRules"
        ref="formRef"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="商户号：" prop="merchantMark">
              <el-input
                placeholder="请填入商户号"
                v-model="formData.merchantMark"
                :disabled="editStatusDisable"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="商户名称：" prop="merchantName">
              <el-input
                placeholder="请填入商户名称"
                v-model="formData.merchantName"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="登录账号：" prop="merchantAccount">
              <el-input
                placeholder="请输入登录账号"
                v-model="formData.merchantAccount"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="登录密码："
              prop="password"
              v-if="titleName == '添加代理商'"
            >
              <el-input
                placeholder="请输入登录账号"
                v-model="formData.password"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="姓名：" prop="merchantLinkman">
              <el-input
                placeholder="请输入姓名"
                v-model="formData.merchantLinkman"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="手机号：" prop="merchantLinkPhone">
              <el-input
                placeholder="请输入手机号"
                v-model="formData.merchantLinkPhone"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="角色：" prop="roleId">
              <el-select
                v-model="formData.roleId"
                placeholder="请选择角色"
                @change="jointFn"
                class="search-input-class"
              >
                <el-option
                  v-for="item in roleOption"
                  :key="item.roleId"
                  :label="item.roleName"
                  :value="item.roleId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <!--          <el-col :span="24">-->
          <!--            <el-form-item label="分成比例：" prop="proportion">-->
          <!--              <el-input-->
          <!--                  placeholder="请输入分成比例"-->
          <!--                  v-model="formData.proportion"-->
          <!--                  controls-position="right"-->
          <!--                  size="small"-->
          <!--                  style="width: 100%"-->
          <!--              ></el-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <el-col :span="24">
            <el-form-item label="地址：" prop="address">
              <el-input
                placeholder="请输入地址"
                v-model="formData.address"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="每日限额(元)：" prop="quotaMoney">
              <el-input
                placeholder="请输入每日提现额度"
                v-model="formData.quotaMoney"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="每日限次：" prop="limitTimes">
              <el-input
                placeholder="请输入每日提现次数"
                v-model="formData.limitTimes"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm">取消 </el-button>
          <el-button type="primary" @click="submitForm('formRef')"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import pageNation from "@/components/base/pageNation";
import { getCurrentInstance, onMounted, reactive, toRefs } from "vue-demi";
import { debounces } from "@/common/utils";
import {
  addMerchantApi,
  delMerchantApi,
  initializedApi,
  merchantPagesApi,
  upMerchantApi,
} from "@/api/commercialManagement/commerciaInfo";
import { requestApi } from "@/utils/requestApi";
import { nextTick, ref } from "vue";
import encryptUtil from "@/utils/encryptUtil";
import { selectInfoApi } from "@/api/authorityManagement/roleManagement";

// const size = ref("");
// const iconStyle = computed(() => {
//   const marginMap = {
//     //   large: '8px',
//     //   default: '6px',
//     //   small: '4px',
//   };
//   return {
//     marginRight: marginMap[size.value] || marginMap.default,
//   };
// });
// const blockMargin = computed(() => {
//   const marginMap = {
//     large: "32px",
//     default: "28px",
//     small: "24px",
//   };
//   return {
//     marginTop: marginMap[size.value] || marginMap.default,
//   };
// });

export default {
  name: "CommerciaInfo",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      tableData: [], // 表格数据
      tableColumnData: [
        // {
        //   prop: "merchantMark",
        //   label: "商户号",
        // },
        {
          prop: "merchantName",
          label: "商户名称",
        },
        {
          prop: "merchantAccount",
          label: "账号",
        },
        {
          prop: "merchantLinkman",
          label: "联系人",
        },
        {
          prop: "merchantLinkPhone",
          label: "联系电话",
        },
        {
          prop: "isDel",
          label: "状态",
        },
        // {
        //   prop: "merchantLinkPhone1",
        //   label: "角色状态",
        // },
        // {
        //   prop: "merchantLinkPhone1",
        //   label: "余额",
        // },
        {
          prop: "parentName",
          label: "上级名称",
        },
        // {
        //   prop: "merchantLinkPhone1",
        //   label: "分成利润",
        // },
        {
          prop: "address",
          label: "地址",
        },
        {
          prop: "quotaMoney",
          label: "每日限额(元)",
        },
        {
          prop: "limitTimes",
          label: "每日限次",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
      ],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      titleName: "添加代理商",
      visible: false,
      userInfo: null,
      userLevel: 0, //用户等级
      selection: [],
      formData: {
        merchantAccount: "", // 登录账号
        merchantLinkman: "", // 姓名
        merchantLinkPhone: "", // 手机号
        roleId: "", // 角色
        proportion: "", // 分成比例
        address: "",
        quotaMoney: null, // 每日提现额度
        limitTimes: null, // 每日提现次数
        merchantName: "",
        merchantMark: "",
        password: "",
      },
      addRules: {
        merchantMark: [
          { required: true, message: "请输入商户号", trigger: "blur" },
        ],
        merchantName: [
          { required: true, message: "请输入商户名称", trigger: "blur" },
        ],
        merchantAccount: [
          { required: true, message: "请输入登录账号", trigger: "blur" },
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' },
        ],
        merchantLinkman: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        merchantLinkPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        roleId: [{ required: true, message: "请选择角色", trigger: "change" }],
        proportion: [
          { required: false, message: "请输入分成比例", trigger: "blur" },
          // { pattern:/^[0-9]*$/, message: '不能小于0',}
        ],
        address: [{ required: false, message: "请输入地址", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        quotaMoney: [
          { required: true, message: "请输入每日提现额度", trigger: "blur" },
        ],
        limitTimes: [
          { required: true, message: "请输入每日提现次数", trigger: "blur" },
        ],
      },
      editStatusDisable: false, // 编辑时不能修改

      allocateDeviceVisible: false, // 分配设备对话框
      // 分配设备form
      allocateDeviceFormData: {
        agent: "",
        network: "",
      },
      allocateDeviceRules: {
        agent: [{ required: true, message: "请选择代理商", trigger: "change" }],
      },

      accountInput: "",
      phoneInput: "",
      deviceAddressInput: "",
      roleInput: "",

      // 角色下拉框
      roleOption: [],
    });

    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      proxy.$nextTick(() => {
        proxy.userLevel = proxy.userInfo.chargingMerchant.level;
      });
      console.log(proxy.userInfo.chargingMerchant.level);
      methods.merchantPages();
      methods.selectInfo();
    });

    // 节流搜索按钮
    const searchInput = debounces(() => {
      methods.merchantPages();
    }, 500);

    const methods = {
      merchantPages() {
        requestApi(
          () => {
            const params = {
              current: data.currentPage,
              size: data.pageSize,
              merchantAccount: data.accountInput,
              merchantLinkphone: data.phoneInput,
              roleId: data.roleInput,
            };
            return merchantPagesApi(params);
          },
          (res) => {
            data.tableData = res.data.records;
            if (data.tableData.length > 0) {
              for (let i = 0; i < data.tableData.length; i++) {
                data.tableData[i].proportion = Number(
                  data.tableData[i].proportion
                );
              }
            }
            proxy.total = res.data.total;
            proxy.currentPage = res.data.current;
            proxy.pageSize = res.data.size;
          }
        );
      },
      handleSizeChange(val) {
        proxy.pageSize = val;
        methods.merchantPages();
      },
      handleCurrentChange(val) {
        proxy.currentPage = val;
        methods.merchantPages();
      },
      // 查询角色
      selectInfo() {
        requestApi(
          () => {
            return selectInfoApi({
              current: 1,
              size: 1000,
            });
          },
          (res) => {
            data.roleOption = res.data.records;
          }
        );
      },
      // 添加按钮
      addClick() {
        data.visible = true;
        data.titleName = "添加代理商";
        data.editStatusDisable = false;
        delete proxy.formData.merchantId;
      },
      // 编辑按钮
      editClick(row) {
        // console.log(row)
        data.titleName = "编辑代理商";
        data.visible = true;
        data.editStatusDisable = true;
        nextTick(() => {
          proxy.formData = JSON.parse(JSON.stringify(row));
          delete proxy.formData.merchantPassword;
          delete proxy.formData.createTime;
          delete proxy.formData.proportion; // 比例
          delete proxy.formData.parentId;
        });
      },
      // 关闭添加对话框
      resetForm() {
        data.visible = false;
        proxy.$refs.formRef.resetFields();
      },
      submitForm(formName) {
        proxy.$refs[formName].validate(async (valid) => {
          if (valid) {
            if (proxy.titleName === "添加代理商") {
              proxy.formData.parentId = proxy.userInfo.merchantId;
              proxy.formData.merchantPassword = encryptUtil.encrypt(
                proxy.formData.password
              );
              await methods.addMerchant(proxy.formData);
              // console.log(proxy.formData)
            } else {
              await methods.upMerchant(proxy.formData);
            }
            await methods.merchantPages();
            methods.resetForm();
          }
        });
      },
      async addMerchant(params) {
        await requestApi(
          () => {
            return addMerchantApi(params);
          },
          (res) => {
            proxy.$notify({
              title: "成功",
              message: res.message,
              type: "success",
              duration: 1000,
            });
          }
        );
      },
      async upMerchant(params) {
        await requestApi(
          () => {
            return upMerchantApi(params);
          },
          (res) => {
            proxy.$notify({
              title: "成功",
              message: res.message,
              type: "success",
              duration: 1000,
            });
          }
        );
      },
      // 删除按钮
      confirmDelete(row) {
        if (row.isDel == 0) {
          proxy.typeVal = 1;
        } else if (row.isDel == 1) {
          proxy.typeVal = 0;
        }
        methods.delMerchant({
          merchantId: row.merchantId,
          type: proxy.typeVal,
        });
      },
      delMerchant(params) {
        requestApi(
          () => {
            return delMerchantApi(params);
          },
          (res) => {
            methods.merchantPages();
            proxy.$notify({
              title: "成功",
              message: res.message,
              type: "success",
              duration: 1000,
            });
          }
        );
      },
      // 点击初始化密码
      initPasswordClick(row) {
        proxy.$msgbox.confirm(
            '是否初始化密码?',
            '警告',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        ).then(() => {
          methods.submitInitPassword(row.merchantId)
        }).catch(() => {
          // proxy.$notify({
          //   title: "错误",
          //   message: '初始化密码失败',
          //   type: "error",
          //   duration: 1000,
          // });
        })
      },
      submitInitPassword(id) {
        requestApi(
          () => {
            return initializedApi({
              merchantId: id,
            });
          },
          (res) => {
            methods.merchantPages();
            proxy.$notify({
              title: "成功",
              message: res.message,
              type: "success",
              duration: 1000,
            });
          }
        );
      },
      // 状态显示
      statusSize(fol) {
        if (fol.isDel == 0) {
          return "正常";
        } else if (fol.isDel == 1) {
          return "已停用";
        } else {
          return "不存在";
        }
      },
    };

    return {
      ...toRefs(data),
      searchInput,
      ...methods,
      // userLevel
    };
  },
};
</script>

  <style lang="less" scoped>
.el-descriptions {
  margin-top: 20px;
}
.cell-item {
  display: flex;
  align-items: center;
}
.margin-top {
  margin-top: 20px;
}
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
  .formClass {
    display: inline-block;
    margin-right: 4px;
  }
}
.page-container {
  width: 100%;
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}
.search-input-class {
  width: 100%;
}

/deep/.el-form-item__label {
  white-space: nowrap;
}
/deep/.el-form-item {
  width: 100%;
}
</style>
